<template>
  <div class="page-wrapper">
    <Loading v-if="loading"></Loading>
    <div class="page-banner bg" :style="{ 'background-image': 'url(' + banner + ')' }">
      <div class="container">
        <div class="page-name common-padding">
          <h1>{{ $t("contact") }}</h1>
        </div>
      </div>
    </div>
    <div class="bg">
    <div class="container  common-padding">
         <div class="contact">
            <div class="contact-1">
               <Form></Form>
            </div>
            <div class="contact-2">
              <iframe :src="url" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div class="contact-3">
              <div>
                 <span>
                    <i class="iconfont icon-dizhi"></i>
                 </span>
                <span>{{ address }}</span>
              </div>
              <div>
                <span>
                  <i class="iconfont icon-shizhong"></i>
                </span>
                <span>{{ time }}</span>
              </div>
              <div>
                <span>
                  <i class="iconfont icon-tel"></i>
                </span>
                <span>{{ phone }}</span>
              </div>
              <div>
                <span>
                  <i class="iconfont icon-chuanzhen"></i>
                </span>
                <span>{{ fax }}</span>
              </div>
              <div>
                <span>
                  <i class="iconfont icon-email-fill"></i>
                </span>
                <span>{{ email }}</span>
              </div>
            </div>
          </div>

      <div class="QR-CODE">
       <a :href="whatsappUrl" target="_blank">
         <img :src="whatspp_qr_code">
       </a>
        <img :src="wechat_qr_code">
      </div>
    </div>
    </div>


    <Copyright></Copyright>

  </div>
</template>

<script>
import Form from "@/components/form";
import Copyright from "@/components/Copyright";
import Loading from "@/components/loading";
import {getContact} from "@/network/main";

export default {
  name: "Contact",
  components:{
    Form,
    Copyright,
    Loading
  },
  data(){
    return {
      loading:true,
      banner:'',
      address:'',
      time:'',
      phone:'',
      fax:'',
      email:'',
      url:'',
      wechat_qr_code:'',
      whatspp_content:'',
      whatspp_qr_code:'',
    }
  },
  created() {
    this.getContact();
  },
  methods:{
    getContact() {
      getContact().then(res => {
        this.loading = false;
        const data = res.data.data
        this.banner = data.banner.call_us_banner;
        this.address = data.info.address
        this.time = data.info.working_hours;
        this.phone = data.info.phone;
        this.fax = data.info.fax;
        this.email = data.info.email;
        this.url = data.info.address_url;
        this.wechat_qr_code = data.info.wechat_qr_code
        this.whatspp_content = data.info.whatspp_content;
        this.whatspp_qr_code = data.info.whatspp_qr_code;
      })
    }
  },
  computed: {
    whatsappUrl() {
      return `https://api.whatsapp.com/send?phone=852${this.whatspp_content}`;
    }
  },

}
</script>

<style scoped>
.bg{
  background-color: rgb(203, 233, 196);
}
.contact{
  display: flex;
  justify-content: space-between;
}
.contact>div{
  width:32%;
}

.contact-2 iframe{
  width: 100%;
  height: 316px;
}
.contact-3>div{
  padding: 10px 0;
  display: flex;
  align-items: center;
}
.contact-3>div span:first-child{
  padding-right: 10px;
}
.QR-CODE{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.QR-CODE img{
  padding: 20px;
}

@media only screen and (min-width:0px) and (max-width:767px){
  .contact {
    flex-wrap: wrap;
  }
  .contact>div{
    width: 100%;
  }
  .contact-2{
   margin: 20px 0;
    order: 2;
  }
  .contact-3{
    order: 1;
  }
  .contact-1{
    order: 3;
  }
  .QR-CODE{
    flex-wrap: wrap;
  }
  .QR-CODE img{
    padding:10px;
  }
 }

</style>